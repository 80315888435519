import React, { useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import _ from 'lodash'

import Dialog from '../../Dialog'
import Analytics from '../../../Analytics'
import RSSFullText from './RSSFullText'
import SentimentIndicator from './SentimentIndicator'
import SocialShare from './SocialShare'
import Star from '../../Sidebar/Star'
import ToggleFromReadList from './ToggleFromReadlist'
import { InstagramEmbed } from 'react-social-media-embed'
import { TwitterTweetEmbed } from 'react-twitter-embed'

import { useSelector, useDispatch } from 'react-redux'
import { getFavourites } from '../../../redux/favourites/actions'
import { removeFromReadlist, addToReadList } from '../../../api/readlist'

import ReactSVG from 'react-svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import missingAvatar from '../../../images/World.svg'
import instagramIcon from '../../../images/Instagram.svg'
import reachIcon from '../../../images/reach-icon.svg'
import externalLinkIcon from '../../../images/external-link.svg'

import { getIconID, nFormatter, getImageFromSource } from '../../../helpers'

import styled from 'styled-components'
import { cssvar } from '../../../styles/var'
import SocialPostDetail from './SocialPostDetail'
import { Select } from 'antd'

const PostModal = ({
  data, update, language, open, close,
}) => {
  const dispatch = useDispatch()
  const { data: favouritesData } = useSelector(({ favourites }) => favourites)
  const { userInfo: user } = useSelector(({ auth }) => auth)

  const isMobile = window.innerWidth <= 799
  const isSafari = /^((?!chrome|CriOS|android).)*safari/i.test(navigator.userAgent) // Exclude Chrome, Android browsers and CriOS (Chrome on iOS)

  const { _source = {}, _id } = data
  const {
    date, owner = {}, type, ig = {}, fb = {}, rss = {}, tw = {}, entity_id, reach, sentiment, message,
  } = _source
  const {
    name, image, tag, tags,
  } = owner

  const isFavourited = favouritesData && favouritesData.find(({ tag: favTag }) => favTag === tag)
  const tagsArr = tags && tags.length > 0 ? _.isArray(tags) ? tags : tags.split(',') : []
  const team = tagsArr.map(tag => (tag.doc_type === 'team' ? { label: tag.label, id: tag.tag } : ''))

  const [addedTags, setAddedTags] = useState(null)

  useEffect(() => {
    if (!favouritesData) {
      dispatch(getFavourites())
    }
  }, [favouritesData])

  useEffect(() => {
    if (!_source) return

    if (_source.addedTags && _source.addedTags.length > 0 && addedTags === null) {
      setAddedTags(_source.addedTags.map(t => ({ key: t.id, label: t.text })))
    }
  }, [_source])

  const handleDelete = (embed) => {
    removeFromReadlist(embed)
      .then(() => {
        update()
      }).catch(err => console.log('error:', err))
  }
  const handleAdd = (embed) => {
    addToReadList(embed)
      .then(() => {
        update()
      }).catch(err => console.log('error:', err))
  }

  const handleAvatarError = (e) => {
    e.target.onerror = null
    e.target.src = missingAvatar
  }

  const canSeeTags = useMemo(() => user.is_admin, [user])

  const getID = (_id, type) => {
    if (_id && type) {
      Analytics.pageView(`/popup/${type}/${_id}`)
    }
    switch (type) {
      case 'tw': {
        const ID = entity_id
        return <TwitterTweetEmbed tweetId={ID} />
      }
      case 'ig': {
        if (isSafari && isMobile) {
          return (
            <SocialPostDetail caption={message} type={ig.type} picture={ig.picture} link={ig.link} social={type} />
          )
        }
        return (
          <InstagramEmbed
            url={ig.link}
          />
        )
      }
      case 'fb': {
        let link = fb.link
        if (fb.type === 'link') {
          link = `https://www.facebook.com/${entity_id.replace('_', '/posts/')}`
        }
        return <SocialPostDetail caption={message} picture={fb.picture} link={link} social={type} />
      }
      case 'rss': {
        return <RSSFullText id={_id} />
      }
      default:
        return null
    }
  }

  return (
    <Dialog
      dialogStyle="width: 100%; max-width: 550px"
      open={open}
      onClose={close}
      fullscreenOnPhone
      header={(
        <Author>
          <AuthorAvatar href={`/explore/${tag}`}>
            <img src={image || missingAvatar} onError={handleAvatarError} alt={name} />
          </AuthorAvatar>
          <AuthorInfo>
            <AuthorName>
              <a href={`/explore/${tag}`}>{name}</a>
              <Star
                favourite={isFavourited}
                team={team}
                tag={tag}
                details={isFavourited && isFavourited.favourite_settings}
                inline />
              <ToggleFromReadList id={_id} remove={handleDelete} add={handleAdd} />
            </AuthorName>
            <PostMeta>
              <PostMetaItem className={type}>
                {type === 'ig'
                  ? <ReactSVG className="instagram-svg" src={instagramIcon} />
                  : <FontAwesomeIcon icon={getIconID(type)} />
                }
                <PostMetaValue>
                  {DateTime.fromISO(date).toRelative({ locale: language })}
                </PostMetaValue>
              </PostMetaItem>
              {reach && (
                <PostMetaItem>
                  <ReactSVG src={reachIcon} />
                  <PostMetaValue>{nFormatter(reach)}</PostMetaValue>
                </PostMetaItem>
              )}
            </PostMeta>
          </AuthorInfo>
        </Author>
      )}
      footer={(
        <FooterWrapper>
          {type === 'rss' && <GoToSource href={rss.link} target="_blank">Go to source</GoToSource>}
          {
            type !== 'rss' && sentiment && sentiment.comparative
              ? <SentimentIndicator data={sentiment.comparative} key={sentiment.comparative} />
              : <div />
          }
          <SocialShare
            id={_id}
            type={type}
            title={name}
            ig={ig}
            fb={fb}
            tw={tw}
            rss={rss}
            previewImage={getImageFromSource(_source)} />
        </FooterWrapper>
      )}>
      <Content>
        {
          canSeeTags && addedTags !== null && addedTags.length > 0
          && (
            <AddedTagsBox>
              <span className="title">Tags:</span>
              <p>
                {
                  addedTags.map((t, i) => (
                    [
                      <a href={`/explore/${t.key}`} key={t.key} target="_blank" rel="noreferrer">
                        {t.label}
                      </a>,
                      i < addedTags.length - 1 && ', ',
                    ]
                  ))
                }
              </p>
            </AddedTagsBox>
          )

        }
        {getID(_id, type)}
      </Content>
    </Dialog>
  )
}

export default PostModal

const Author = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
`
const AuthorAvatar = styled.a`
  flex: 0 0 auto;
  margin-right: 15px;
  img {
    height: 40px;
    border-radius: 4px;
  }
`
const AuthorInfo = styled.div`
  flex: 1 1 auto;
`
const AuthorName = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 3px;
  font-size: 16px;
  font-weight: 550;
  line-height: 1;
  a {
    margin-right: 10px;
    color: #fff;
    &:hover {
      color: ${cssvar('oldPrimaryColor')}
    }
  }
`
const PostMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 7px;
`
const PostMetaItem = styled.span`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  .instagram-svg {
    height: 12px;
    margin-top: 3px;
  }
  svg {
    width: auto !important;
    height: 12px !important;
    color: #fff !important;
    margin-top: -2px;
    path {
      fill: #fff !important;
    }
  }
`
const PostMetaValue = styled.span`
  font-size: 12px;
  color: #fff;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .twitter-tweet {
    margin: 0 !important;
  }
`

const AddedTagsBox = styled.div`
  margin: 15px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${cssvar('mainColor')};
  p {
    margin: 0;
    font-size: 14px;
    color: ${cssvar('mainColor')};
  }
}
`

const FooterWrapper = styled.div`
display: flex;
align - items: center;
justify - content: space - between;
`
const GoToSource = styled.a`
display: flex;
align - items: center;
font - size: 16px;
color: ${cssvar('mainColor')};
font - weight: 550;
cursor: pointer;
  &:before {
  content: '';
  width: 16px;
  height: 16px;
  margin - right: 7px;
  background - image: url(${externalLinkIcon});
  background - size: cover;
}
  &:hover {
  color: ${cssvar('primaryHoverColor')};
}
`